import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  Modal,
  ModalBody,
  DropdownItem,
  Badge,
  Spinner,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  Row,
  RSelect,
} from "../../../components/Component";
import { formatDate } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { DeleteDiscount, getAllDiscounts, UpdateDiscount } from "../../../network/apis/discounts";
import DiscountsForm from "./DiscountsForm";

const DiscountsList = () => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [discountsData, setDiscountsData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [selectedFilterStatus, setSelectedFilterStatus] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [showCreateDiscountForm, setShowCreateDiscountForm] = useState(false);
  const [showEditDiscountForm, setShowEditDiscountForm] = useState(false);

  const deleteDiscount = async (uuid) => {
    try {
      setIsDeleting(true);
      const res = await DeleteDiscount(uuid);

      if (res.status === "success") {
        toast.success(res.message);
        fetchDiscounts();
        setShowDeleteModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? "An error occurred");
    } finally {
      setIsDeleting(false);
    }
  };

  const updateDiscount = async (uuid, status) => {
    try {
      setIsUpdating(true);
      const res = await UpdateDiscount(uuid, {
        ...detail,
        status: status,
      });

      if (res.status === "success") {
        toast.success(res.message);
        fetchDiscounts();
        setShowUpdateModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? "An error occurred");
    } finally {
      setIsUpdating(false);
    }
  };

  const sortingFunc = (params) => {
    setSortState(params);
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = discountsData.filter((item) => {
        return item?.name?.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...discountsData]);
    }
  }, [onSearchText, discountsData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.uuid === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [fetching, setFetching] = useState(false);

  const fetchDiscounts = async () => {
    try {
      setFetching(true);
      const res = await getAllDiscounts();

      if (res.status === "success") {
        setDiscountsData(res.data);
        setData(res.data);
      } else {
        toast.error(res.message);
        setDiscountsData([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "An error occurred");
      setDiscountsData([]);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newData = discountsData;

    // Filter
    if (selectedFilterStatus) {
      newData = newData.filter((item) => item?.status?.toLowerCase() === selectedFilterStatus?.toLowerCase());
    }

    // Sorting
    if (sort) {
      if (sort === "asc") {
        newData = newData.sort((a, b) => a.date.localeCompare(b.date));
      } else if (sort === "dsc") {
        newData = newData.sort((a, b) => b.date.localeCompare(a.date));
      }
    }

    // Pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = newData.slice(indexOfFirstItem, indexOfLastItem);
    setData(currentItems);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, itemPerPage, sort, discountsData, selectedFilterStatus]);

  return (
    <React.Fragment>
      <Head title="Discounts"></Head>
      {fetching ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        <Content>
          {!showCreateDiscountForm && !showEditDiscountForm ? (
            <>
              <BlockHead size="sm">
                <BlockBetween>
                  <BlockHeadContent className="w-100">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                      <BlockTitle page>Discounts</BlockTitle>
                      <button className="btn btn-outline-primary" onClick={() => setShowCreateDiscountForm(true)}>
                        Add New Discount
                      </button>
                    </div>
                    <BlockDes className="text-soft">
                      <p>You have total {discountsData.length} discounts.</p>
                    </BlockDes>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <Card className="card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title ">
                          <h5 className="title">All Discounts</h5>
                        </div>
                        <div className="card-tools me-n1">
                          <ul className="btn-toolbar">
                            <li>
                              <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                <Icon name="search"></Icon>
                              </Button>
                            </li>
                            <li className="btn-toolbar-sep"></li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  end
                                  className="filter-wg dropdown-menu-lg"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Users</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="12">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect
                                            options={[
                                              {
                                                label: "Paid",
                                                value: "Paid",
                                              },
                                              {
                                                label: "Expired",
                                                value: "Expired",
                                              },
                                              {
                                                label: "Pending",
                                                value: "Pending",
                                              },
                                              {
                                                label: "Deleted",
                                                value: "Deleted",
                                              },
                                            ]}
                                            placeholder="Any Status"
                                            onChange={(e) => {
                                              setSelectedFilterStatus(e.value);
                                            }}
                                            value={{
                                              label: selectedFilterStatus ?? "Any Status",
                                              value: selectedFilterStatus ?? "",
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setSelectedFilterStatus("");
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>

                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 30 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(30);
                                        }}
                                      >
                                        30
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortingFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortingFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                          <div className="search-content">
                            <Button
                              className="search-back btn-icon toggle-search"
                              onClick={() => {
                                setSearchText("");
                                toggle();
                              }}
                            >
                              <Icon name="arrow-left"></Icon>
                            </Button>
                            <input
                              type="text"
                              className="form-control border-transparent form-focus-none"
                              placeholder="Search by ID, User, Plan"
                              value={onSearchText}
                              onChange={(e) => onFilterChange(e)}
                            />
                            <Button className="search-submit btn-icon">
                              <Icon name="search"></Icon>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0">
                      <table className="table table-tranx">
                        <thead>
                          <tr className="tb-tnx-head">
                            <th className="tb-tnx-id">
                              <span className="">Name</span>
                            </th>
                            <th className="tb-tnx-id">
                              <span className="">Type</span>
                            </th>

                            <th className="tb-tnx-id">
                              <span className="">Created At</span>
                            </th>
                            <th className="tb-tnx-id">
                              <span className="">Expires On</span>
                            </th>
                            <th className="tb-tnx-id">
                              <span className="">Usage Count</span>
                            </th>
                            <th className="tb-tnx-id">
                              <span className="">Status</span>
                            </th>
                            <th className="tb-tnx-action">
                              <span>&nbsp;</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0
                            ? data.map((item) => {
                                return (
                                  <tr key={item.uuid} className="tb-tnx-item">
                                    <td className="tb-tnx-id">
                                      <a
                                        href="#ref"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          loadDetail(item.uuid);
                                          setViewModal(true);
                                        }}
                                      >
                                        <span>{item.name}</span>
                                      </a>
                                    </td>
                                    <td className="tb-tnx-id">
                                      {item.type === "percentage" ? "Percentage" : "Fixed Value"}
                                    </td>

                                    <td className="tb-tnx-id">{formatDate(item.createdAt, "DD-MM-YYYY")}</td>
                                    <td className="tb-tnx-id">
                                      {item?.expiresOn ? formatDate(item.expiresOn, "DD-MM-YYYY") : "No Expiry Date"}
                                    </td>
                                    <td className="tb-tnx-id">{item?.usageCount ?? 0}</td>
                                    <td className="tb-tnx-id">
                                      <div className="tb-tnx-status">
                                        <Badge
                                          className="badge-dot"
                                          color={
                                            item.status === "active"
                                              ? "success"
                                              : item.status === "inactive"
                                              ? "warning"
                                              : "danger"
                                          }
                                          style={{ textTransform: "capitalize" }}
                                        >
                                          {item.status}
                                        </Badge>
                                      </div>
                                    </td>

                                    <td className="tb-tnx-action">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                          <ul className="link-list-plain">
                                            <li
                                              onClick={() => {
                                                loadDetail(item.uuid);
                                                setViewModal(true);
                                              }}
                                            >
                                              <DropdownItem
                                                tag="a"
                                                href="#view"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                View Details
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#update"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  setShowEditDiscountForm(true);
                                                  loadDetail(item.uuid);
                                                }}
                                              >
                                                Edit
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#update"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  loadDetail(item.uuid);
                                                  setShowUpdateModal(true);
                                                }}
                                              >
                                                {item.status === "active" ? "Deactivate" : "Activate"}
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#delete"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  setShowDeleteModal(true);
                                                  loadDetail(item.uuid);
                                                }}
                                              >
                                                Delete
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-inner">
                      {discountsData.length > 0 ? (
                        <PaginationComponent
                          noDown
                          itemPerPage={itemPerPage}
                          totalItems={discountsData.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No data found</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </Block>
            </>
          ) : (
            <DiscountsForm
              hideForm={() => {
                setShowCreateDiscountForm(false);
                setShowEditDiscountForm(false);
              }}
              refreshList={() => {
                fetchDiscounts();
              }}
              isEditForm={showEditDiscountForm ? true : false}
              discountData={showEditDiscountForm ? detail : null}
            />
          )}

          {/* View Modal */}
          <Modal isOpen={viewModal} toggle={() => setViewModal(false)} className="modal-dialog-centered" size="md">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setViewModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head border-bottom pb-4">
                <h4 className="nk-modal-title title">Discount Details</h4>
              </div>
              <div className="nk-tnx-details mt-sm-3">
                <Row className="gy-3">
                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Discount Name / Code</h6>
                    <span className="caption-text">{detail?.name}</span>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Status</h6>
                    <Badge
                      color={
                        detail?.status?.toLowerCase() === "active"
                          ? "success"
                          : detail?.status?.toLowerCase() === "inactive"
                          ? "warning"
                          : "danger"
                      }
                      size="md"
                      style={{ textTransform: "capitalize" }}
                    >
                      {detail?.status}
                    </Badge>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Creation Date</h6>
                    <span className="caption-text">{formatDate(detail?.createdAt, "DD-MM-YYYY")}</span>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Expires On </h6>
                    <span className="caption-text">
                      {detail?.expiresOn ? formatDate(detail?.expiresOn, "DD-MM-YYYY") : "No Expiry Date"}
                    </span>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Discount Type</h6>
                    <span className="caption-text">{detail?.type === "percentage" ? "Percentage" : "Fixed Value"}</span>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Discount Value (On total value)</h6>
                    <span className="caption-text">
                      {detail?.type === "percentage" ? (
                        `${detail?.usdValue * 100}% off`
                      ) : (
                        <>
                          <span>USD {detail?.usdValue} off</span>
                          <br />
                          <span>IRR {detail?.tomansValue} off</span>
                        </>
                      )}
                    </span>
                  </Col>
                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Minimum Order Value</h6>
                    <span className="caption-text">
                      {detail.usdMinOrderValue && detail.tomansMinOrderValue ? (
                        <>
                          <span>USD {detail?.usdMinOrderValue}</span>
                          <br />
                          <span>IRR {detail?.tomansMinOrderValue}</span>
                        </>
                      ) : (
                        "No Minimum Order Value"
                      )}
                    </span>
                  </Col>

                  <Col lg={6} className="border-bottom pb-3">
                    <h6 className="sub-text">Maximum Discount Value</h6>
                    <span className="caption-text">
                      {detail.usdMaxDiscount && detail.tomansMaxDiscount ? (
                        <>
                          <span>USD {detail?.usdMaxDiscount}</span>
                          <br />
                          <span>IRR {detail?.tomansMaxDiscount}</span>
                        </>
                      ) : (
                        "No Maximum Discount Limit"
                      )}
                    </span>
                  </Col>

                  <Col lg={6}>
                    <h6 className="sub-text">Maximum Usage Limit</h6>
                    <span className="caption-text">
                      {detail.maxUsage ? (
                        <>
                          <span>{detail?.maxUsage}</span>
                        </>
                      ) : (
                        "Unlimited"
                      )}
                    </span>
                  </Col>

                  <Col lg={6}>
                    <h6 className="sub-text">Total Usage</h6>
                    <span className="caption-text">{detail?.usageCount ?? 0}</span>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>

          {/* Delete Modal */}
          <Modal
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(false)}
            className="modal-dialog-centered"
            size="sm"
          >
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowDeleteModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head">
                <h4 className="nk-modal-title title">
                  Are you sure you want to delete <span className="text-primary">"{detail?.name}"</span> discount?
                </h4>
              </div>

              <div className="nk-modal-head mt-4">
                <Button
                  className="btn btn-primary"
                  disabled={isDeleting}
                  onClick={() => {
                    deleteDiscount(detail?.uuid);
                  }}
                >
                  {isDeleting ? <Spinner size="sm" color="light" /> : "Delete discount"}
                </Button>

                <Button
                  onClick={() => setShowDeleteModal(false)}
                  className="btn btn-outline-secondary mx-2"
                  disabled={isDeleting}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>

          {/* Update Status Modal */}
          <Modal
            isOpen={showUpdateModal}
            toggle={() => setShowUpdateModal(false)}
            className="modal-dialog-centered"
            size="sm"
          >
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowUpdateModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head">
                <h4 className="nk-modal-title title">
                  Are you sure you want to {detail?.status === "active" ? "deactivate" : "activate"}{" "}
                  <span className="text-primary">"{detail?.name}"</span> discount?
                </h4>
              </div>

              <div className="nk-modal-head mt-4">
                <Button
                  className="btn btn-primary"
                  disabled={isUpdating}
                  onClick={() => {
                    updateDiscount(detail?.uuid, detail?.status === "active" ? "inactive" : "active");
                  }}
                >
                  {isUpdating ? (
                    <Spinner size="sm" color="light" />
                  ) : detail?.status === "active" ? (
                    "Deactivate"
                  ) : (
                    "Activate"
                  )}
                </Button>

                <Button
                  onClick={() => setShowUpdateModal(false)}
                  className="btn btn-outline-secondary mx-2"
                  disabled={isUpdating}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Content>
      )}
    </React.Fragment>
  );
};

export default DiscountsList;

import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";

import Homepage from "../pages/Homepage";
import Analytics from "../pages/Analytics";
import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";

import UserListRegular from "../pages/pre-built/user-manage/UserListRegular";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";
import InvoiceList from "../pages/pre-built/order-invoice/InvoiceList";
import InvoiceDetails from "../pages/pre-built/order-invoice/InvoiceDetails";
import PaymentHistory from "../pages/pre-built/order-invoice/PaymentHistory";
import InvoicePrint from "../pages/pre-built/order-invoice/InvoicePrint";
import PricingTable from "../pages/pre-built/pricing-table/PricingTable";
import GalleryPreview from "../pages/pre-built/gallery/GalleryCardPreview";

import AppMessages from "../pages/app/messages/Messages";
import Chat from "../pages/app/chat/ChatContainer";
import Kanban from "../pages/app/kanban/Kanban";
import FileManager from "../pages/app/file-manager/FileManager";
import FileManagerFiles from "../pages/app/file-manager/FileManagerFiles";
import FileManagerShared from "../pages/app/file-manager/FileManagerShared";
import FileManagerStarred from "../pages/app/file-manager/FileManagerStarred";
import FileManagerRecovery from "../pages/app/file-manager/FileManagerRecovery";
import FileManagerSettings from "../pages/app/file-manager/FileManagerSettings";
import Inbox from "../pages/app/inbox/Inbox";
import Calender from "../pages/app/calender/Calender";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import EmailTemplate from "../pages/components/email-template/Email";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import EmailVerification from "../pages/auth/EmailVerification";
import VerifyEmail from "../pages/auth/VerifyEmail";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import LayoutApp from "../layout/Index-app";
import AdminUserList from "../pages/pre-built/user-manage/AdminUserList";
import AllSubscriptions from "../pages/pre-built/order-invoice/AllSubscriptions";
import DiscountsList from "../pages/pre-built/discounts/DiscountsList";

const Pages = ({ authenticated = false, emailVerified = false }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {authenticated && emailVerified ? (
        <>
          <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
            <Route index element={<Homepage />}></Route>
            <Route path="analytics" element={<Analytics />}></Route>
            <Route path="_blank" element={<Blank />}></Route>

            <Route element={<UserContextProvider />}>
              <Route path="users" element={<UserListRegular />}></Route>
              <Route path="user/:userId" element={<UserDetails />}></Route>
              <Route path="admin-users" element={<AdminUserList />}></Route>
            </Route>

            <Route element={<UserProfileLayout />}>
              <Route path="user-profile-notification" element={<UserProfileNotification />}></Route>
              <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
              <Route path="user-profile-activity" element={<UserProfileActivity />}></Route>
              <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
            </Route>

            <Route element={<ProductContextProvider />}>
              <Route path="product-list" element={<ProductList />}></Route>
              <Route path="product-card" element={<ProductCard />}></Route>
              <Route path="product-details/:productId" element={<ProductDetails />}></Route>
            </Route>

            <Route path="subscriptions" element={<AllSubscriptions />}></Route>
            <Route path="history-payment" element={<PaymentHistory />}></Route>
            <Route path="invoice-list" element={<InvoiceList />}></Route>
            <Route path="invoice-details/:invoiceId" element={<InvoiceDetails />}></Route>
            <Route path="pricing-table" element={<PricingTable />}></Route>
            <Route path="image-gallery" element={<GalleryPreview />}></Route>

            {/* Discounts */}
            <Route path="discounts" element={<DiscountsList />}></Route>

            <Route path="pages">
              <Route path="terms-policy" element={<Terms />}></Route>
              <Route path="faq" element={<Faq />}></Route>
              <Route path="regular-v1" element={<Regularv1 />}></Route>
              <Route path="regular-v2" element={<Regularv2 />}></Route>
            </Route>
          </Route>

          <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutApp />}>
            <Route path="app-messages" element={<AppMessages />}></Route>
            <Route path="app-chat" element={<Chat />}></Route>
            <Route path="app-calender" element={<Calender />}></Route>
            <Route path="app-inbox" element={<Inbox />}></Route>
            <Route path="app-kanban" element={<Kanban />}></Route>

            <Route path="app-file-manager">
              <Route index element={<FileManager />}></Route>
              <Route path="files" element={<FileManagerFiles />}></Route>
              <Route path="starred" element={<FileManagerStarred />}></Route>
              <Route path="shared" element={<FileManagerShared />}></Route>
              <Route path="recovery" element={<FileManagerRecovery />}></Route>
              <Route path="settings" element={<FileManagerSettings />}></Route>
            </Route>
          </Route>

          <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
            <Route path="errors">
              <Route path="404-modern" element={<Error404Modern />}></Route>
              <Route path="404-classic" element={<Error404Classic />}></Route>
              <Route path="504-modern" element={<Error504Modern />}></Route>
              <Route path="504-classic" element={<Error504Classic />}></Route>
            </Route>
            <Route path="invoice-print/:invoiceId" element={<InvoicePrint />}></Route>
          </Route>
          <Route path="email-template" element={<EmailTemplate />}></Route>
          <Route path="*" element={<Navigate to="/" />}></Route>
        </>
      ) : authenticated && !emailVerified ? (
        <>
          <Route path="/" element={<LayoutNoSidebar />}>
            <Route index path="/" element={<EmailVerification />}></Route>
            <Route path="auth-action" element={<VerifyEmail />}></Route>
            <Route path="*" element={<EmailVerification />}></Route>
          </Route>
        </>
      ) : (
        <>
          <Route path="" element={<LayoutNoSidebar />}>
            <Route index element={<Login />}></Route>
            <Route path="auth-reset" element={<ForgotPassword />}></Route>
            <Route path="auth-login" element={<Login />}></Route>
            <Route path="*" element={<Navigate to="/" />}></Route>
          </Route>
        </>
      )}
    </Routes>
  );
};
export default Pages;

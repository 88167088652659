import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import { Card, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { AddDiscount, UpdateDiscount } from "../../../network/apis/discounts";

function DiscountsForm({ hideForm = () => {}, refreshList = () => {}, isEditForm = false, discountData = null }) {
  const [name, setName] = useState("");
  const [type, setType] = useState("percentage");
  const [usdValue, setUsdValue] = useState(0);
  const [tomansValue, setTomansValue] = useState(0);
  const [hasMinOrderValue, setHasMinOrderValue] = useState(false);
  const [usdMinOrderValue, setUsdMinOrderValue] = useState(0);
  const [tomansMinOrderValue, setTomansMinOrderValue] = useState(0);
  const [hasMaxDiscount, setHasMaxDiscount] = useState(false);
  const [usdMaxDiscount, setUsdMaxDiscount] = useState(0);
  const [tomansMaxDiscount, setTomansMaxDiscount] = useState(0);
  const [hasExpiryDate, setHasExpiryDate] = useState(false);
  const [expiresOn, setExpiresOn] = useState(null);
  const [hasMaxUsage, setHasMaxUsage] = useState(false);
  const [maxUsage, setMaxUsage] = useState(0);

  // Error States
  const [isNameError, setIsNameError] = useState(false);
  const [isUsdValueError, setIsUsdValueError] = useState(false);
  const [isTomansValueError, setIsTomansValueError] = useState(false);
  const [isUsdMinOrderValueError, setIsUsdMinOrderValueError] = useState(false);
  const [isTomansMinOrderValueError, setIsTomansMinOrderValueError] = useState(false);
  const [isUsdMaxDiscountError, setIsUsdMaxDiscountError] = useState(false);
  const [isTomansMaxDiscountError, setIsTomansMaxDiscountError] = useState(false);
  const [isExpiresOnError, setIsExpiresOnError] = useState(false);
  const [isMaxUsageError, setIsMaxUsageError] = useState(false);

  // Loading State
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    if (
      name?.trim()?.length > 0 &&
      // Check if type is percentage. Then check if the value is between 0 and 100
      (type === "percentage" ? usdValue > 0 && usdValue <= 100 : true) &&
      // check if type is fixed. Then check if the value is greater than 0
      (type === "fixed" ? usdValue > 0 : true) &&
      (type === "fixed" ? tomansValue > 0 : true) &&
      // Check if hasMinOrderValue is true. Then check if the value is greater than 0
      (hasMinOrderValue ? usdMinOrderValue > 0 : true) &&
      (hasMinOrderValue ? tomansMinOrderValue > 0 : true) &&
      // Check if hasMaxDiscount is true. Then check if the value is greater than 0
      (hasMaxDiscount ? usdMaxDiscount > 0 : true) &&
      (hasMaxDiscount ? tomansMaxDiscount > 0 : true) &&
      // Check if hasExpiryDate is true. Then check if the value is not null
      (hasExpiryDate ? expiresOn !== null && expiresOn !== "" && new Date(expiresOn) >= new Date() : true) &&
      // Check if hasMaxUsage is true. Then check if the value is greater than 0
      (hasMaxUsage ? maxUsage > 0 : true)
    ) {
      const data = {
        name: name,
        status: !isEditForm ? "active" : discountData?.status ?? "active",
        ...(type === "percentage" && {
          type: "percentage",
          usdValue: usdValue / 100,
          tomansValue: usdValue / 100,
        }),
        ...(type === "fixed" && {
          type: "fixed",
          usdValue: usdValue,
          tomansValue: tomansValue,
        }),

        ...(hasMinOrderValue
          ? {
              usdMinOrderValue: usdMinOrderValue,
              tomansMinOrderValue: tomansMinOrderValue,
            }
          : {
              usdMinOrderValue: null,
              tomansMinOrderValue: null,
            }),

        ...(hasMaxDiscount && type === "percentage"
          ? {
              usdMaxDiscount: usdMaxDiscount,
              tomansMaxDiscount: tomansMaxDiscount,
            }
          : {
              usdMaxDiscount: null,
              tomansMaxDiscount: null,
            }),

        ...(hasExpiryDate
          ? {
              expiresOn: expiresOn,
            }
          : {
              expiresOn: null,
            }),

        ...(hasMaxUsage
          ? {
              maxUsage: maxUsage,
            }
          : {
              maxUsage: null,
            }),
      };

      if (isEditForm) {
        editDiscount(data);
      } else {
        createDiscount(data);
      }
    } else {
      if (name?.trim()?.length === 0) {
        setIsNameError(true);
      }

      if (type === "percentage" && (usdValue <= 0 || usdValue > 100 || isNaN(usdValue))) {
        setIsUsdValueError(true);
      }

      if (type === "percentage" && (tomansValue < 0 || tomansValue > 100 || isNaN(tomansValue))) {
        setIsTomansValueError(true);
      }

      if (type === "fixed" && (usdValue <= 0 || isNaN(usdValue))) {
        setIsUsdValueError(true);
      }

      if (type === "fixed" && (tomansValue <= 0 || isNaN(tomansValue))) {
        setIsTomansValueError(true);
      }

      if (hasMinOrderValue && (usdMinOrderValue <= 0 || isNaN(usdMinOrderValue))) {
        setIsUsdMinOrderValueError(true);
      }

      if (hasMinOrderValue && (tomansMinOrderValue <= 0 || isNaN(tomansMinOrderValue))) {
        setIsTomansMinOrderValueError(true);
      }

      if (hasMaxDiscount && (usdMaxDiscount <= 0 || isNaN(usdMaxDiscount))) {
        setIsUsdMaxDiscountError(true);
      }

      if (hasMaxDiscount && (tomansMaxDiscount <= 0 || isNaN(tomansMaxDiscount))) {
        setIsTomansMaxDiscountError(true);
      }

      if (hasExpiryDate && (expiresOn === null || expiresOn === "" || new Date(expiresOn) < new Date())) {
        setIsExpiresOnError(true);
      }

      if (hasMaxUsage && (maxUsage <= 0 || isNaN(maxUsage))) {
        setIsMaxUsageError(true);
      }

      toast.warn("Please fill all the required fields correctly");
    }
  };

  const createDiscount = async (data) => {
    setIsSaving(true);
    const res = await AddDiscount(data);
    setIsSaving(false);

    if (res.status === "success") {
      toast.success(res.message);
      hideForm();
      refreshList();
    } else {
      toast.error(res.message);
    }
  };

  const editDiscount = async (data) => {
    setIsSaving(true);
    const res = await UpdateDiscount(discountData.uuid, data);
    setIsSaving(false);

    if (res.status === "success") {
      toast.success(res.message);
      hideForm();
      refreshList();
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (discountData && isEditForm) {
      setName(discountData.name);
      setType(discountData.type);

      if (discountData.type === "percentage") {
        setUsdValue(discountData.usdValue * 100);
        setTomansValue(discountData.tomansValue * 100);
      } else {
        setUsdValue(discountData.usdValue);
        setTomansValue(discountData.tomansValue);
      }

      if (discountData.usdMinOrderValue && discountData.tomansMinOrderValue) {
        setHasMinOrderValue(true);
        setUsdMinOrderValue(discountData.usdMinOrderValue);
        setTomansMinOrderValue(discountData.tomansMinOrderValue);
      } else {
        setHasMinOrderValue(false);
        setUsdMinOrderValue(0);
        setTomansMinOrderValue(0);
      }

      if (discountData.usdMaxDiscount && discountData.tomansMaxDiscount) {
        setHasMaxDiscount(true);
        setUsdMaxDiscount(discountData.usdMaxDiscount);
        setTomansMaxDiscount(discountData.tomansMaxDiscount);
      } else {
        setHasMaxDiscount(false);
        setUsdMaxDiscount(0);
        setTomansMaxDiscount(0);
      }

      if (discountData.expiresOn) {
        setHasExpiryDate(true);
        setExpiresOn(discountData.expiresOn);
      } else {
        setHasExpiryDate(false);
        setExpiresOn(null);
      }

      if (discountData.maxUsage) {
        setHasMaxUsage(true);
        setMaxUsage(discountData.maxUsage ?? 0);
      }

      setIsNameError(false);
      setIsUsdValueError(false);
      setIsTomansValueError(false);
      setIsUsdMinOrderValueError(false);
      setIsTomansMinOrderValueError(false);
      setIsUsdMaxDiscountError(false);
      setIsTomansMaxDiscountError(false);
      setIsExpiresOnError(false);
      setIsMaxUsageError(false);
    }
  }, [discountData, isEditForm]);

  return (
    <React.Fragment>
      <Head title="Discounts Form"></Head>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent className="w-100">
            <div className="d-flex flex-row align-items-center justify-content-start w-100">
              <button
                className="btn btn-ghost p-0"
                onClick={() => {
                  hideForm();
                }}
              >
                <Icon
                  name="arrow-left"
                  className="me-2"
                  style={{
                    fontSize: "2rem",
                  }}
                ></Icon>
              </button>
              <BlockTitle page>{isEditForm ? "Edit Discount" : "Add New Discount"}</BlockTitle>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Row>
          <Col md={8}>
            <Card className="card-bordered card-stretch p-3">
              <div>
                <h6 className="text-soft">Discount Name / Code</h6>
                <input
                  type="text"
                  className="form-control"
                  style={{
                    padding: "0.7rem 1rem",
                    borderColor: isNameError ? "red" : "inherit",
                    color: isNameError ? "red" : "inherit",
                  }}
                  placeholder="Enter Discount Name / Code"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setIsNameError(false);
                  }}
                />
              </div>
            </Card>

            <Card className="card-bordered card-stretch p-3 mt-3">
              <div>
                <h6 className="text-soft">Discount Type & Value</h6>
                <label className="d-flex align-items-center" htmlFor="percentage">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="type"
                    id="percentage"
                    checked={type === "percentage"}
                    onChange={(e) => {
                      setType("percentage");
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Percentage
                  </span>
                </label>
                {type === "percentage" && (
                  <input
                    type="number"
                    className="form-control my-2"
                    style={{
                      padding: "0.7rem 1rem",
                      borderColor: isUsdValueError ? "red" : "inherit",
                      color: isUsdValueError ? "red" : "inherit",
                    }}
                    placeholder="Enter Discount Percentage"
                    value={usdValue}
                    min={0}
                    max={100}
                    onChange={(e) => {
                      const intValue = parseInt(e.target.value);
                      if (intValue > 100) {
                        setUsdValue(100);
                        setTomansValue(100);
                        return;
                      }

                      if (intValue < 0) {
                        setUsdValue(0);
                        setTomansValue(0);
                        return;
                      }

                      setUsdValue(intValue);
                      setTomansValue(intValue);
                      setIsUsdValueError(false);
                      setIsTomansValueError(false);
                    }}
                  />
                )}
                <label className="d-flex align-items-center" htmlFor="fixed">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="type"
                    id="fixed"
                    checked={type === "fixed"}
                    onChange={(e) => {
                      setType("fixed");
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Fixed Amount
                  </span>
                </label>
                {type === "fixed" && (
                  <>
                    <label className="text-soft mt-2">USD Value</label>
                    <input
                      type="number"
                      className="form-control"
                      style={{
                        padding: "0.7rem 1rem",
                        borderColor: isUsdValueError ? "red" : "inherit",
                        color: isUsdValueError ? "red" : "inherit",
                      }}
                      placeholder="Enter Discount Value in USD"
                      value={usdValue}
                      min={0}
                      onChange={(e) => {
                        const intValue = parseInt(e.target.value);
                        if (intValue < 0) {
                          setUsdValue(0);
                          return;
                        }

                        setUsdValue(intValue);
                        setIsUsdValueError(false);
                      }}
                    />
                    <label className="text-soft mt-2">Tomans Value</label>
                    <input
                      type="number"
                      className="form-control"
                      style={{
                        padding: "0.7rem 1rem",
                        borderColor: isTomansValueError ? "red" : "inherit",
                        color: isTomansValueError ? "red" : "inherit",
                      }}
                      placeholder="Enter Discount Value in Tomans"
                      value={tomansValue}
                      min={0}
                      onChange={(e) => {
                        const intValue = parseInt(e.target.value);
                        if (intValue < 0) {
                          setTomansValue(0);
                          return;
                        }

                        setTomansValue(intValue);
                        setIsTomansValueError(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Card>

            <Card className="card-bordered card-stretch p-3 mt-3">
              <div>
                <h6 className="text-soft">Min Order Value</h6>
                <label className="d-flex align-items-center" htmlFor="noMinOrderValue">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="minOrderValue"
                    id="noMinOrderValue"
                    checked={hasMinOrderValue === false}
                    onChange={(e) => {
                      setHasMinOrderValue(false);
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    No Min Order Value
                  </span>
                </label>

                <label className="d-flex align-items-center" htmlFor="hasMinOrderValue">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="minOrderValue"
                    id="hasMinOrderValue"
                    checked={hasMinOrderValue === true}
                    onChange={(e) => {
                      setHasMinOrderValue(true);
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Set Min Order Value
                  </span>
                </label>
                {hasMinOrderValue && (
                  <>
                    <label className="text-soft mt-2">USD Value</label>
                    <input
                      type="number"
                      className="form-control"
                      style={{
                        padding: "0.7rem 1rem",
                        borderColor: isUsdMinOrderValueError ? "red" : "inherit",
                        color: isUsdMinOrderValueError ? "red" : "inherit",
                      }}
                      placeholder="Enter Min Order Value in USD"
                      value={usdMinOrderValue}
                      min={0}
                      onChange={(e) => {
                        const intValue = parseInt(e.target.value);
                        if (intValue < 0) {
                          setUsdMinOrderValue(0);
                          return;
                        }

                        setUsdMinOrderValue(intValue);
                        setIsUsdMinOrderValueError(false);
                      }}
                    />
                    <label className="text-soft mt-2">Tomans Value</label>
                    <input
                      type="number"
                      className="form-control"
                      style={{
                        padding: "0.7rem 1rem",
                        borderColor: isTomansMinOrderValueError ? "red" : "inherit",
                        color: isTomansMinOrderValueError ? "red" : "inherit",
                      }}
                      placeholder="Enter Min Order Value in Tomans"
                      value={tomansMinOrderValue}
                      min={0}
                      onChange={(e) => {
                        const intValue = parseInt(e.target.value);
                        if (intValue < 0) {
                          setTomansMinOrderValue(0);
                          return;
                        }

                        setTomansMinOrderValue(intValue);
                        setIsTomansMinOrderValueError(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Card>

            {type === "percentage" && (
              <Card className="card-bordered card-stretch p-3 mt-3">
                <div>
                  <h6 className="text-soft">Max Discount Limit</h6>
                  <label className="d-flex align-items-center" htmlFor="noMaxDiscount">
                    <input
                      type="radio"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "0.5rem",
                      }}
                      name="maxDiscount"
                      id="noMaxDiscount"
                      checked={hasMaxDiscount === false}
                      onChange={(e) => {
                        setHasMaxDiscount(false);
                      }}
                    />
                    <span
                      className="text-soft"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      No Max Discount limit
                    </span>
                  </label>

                  <label className="d-flex align-items-center" htmlFor="hasMaxDiscount">
                    <input
                      type="radio"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "0.5rem",
                      }}
                      name="maxDiscount"
                      id="hasMaxDiscount"
                      checked={hasMaxDiscount === true}
                      onChange={(e) => {
                        setHasMaxDiscount(true);
                      }}
                    />
                    <span
                      className="text-soft"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      Set Max Discount limit
                    </span>
                  </label>
                  {hasMaxDiscount && (
                    <>
                      <label className="text-soft mt-2">USD Value</label>
                      <input
                        type="number"
                        className="form-control"
                        style={{
                          padding: "0.7rem 1rem",
                          borderColor: isUsdMaxDiscountError ? "red" : "inherit",
                          color: isUsdMaxDiscountError ? "red" : "inherit",
                        }}
                        placeholder="Enter Max Discount in USD"
                        value={usdMaxDiscount}
                        min={0}
                        onChange={(e) => {
                          const intValue = parseInt(e.target.value);
                          if (intValue < 0) {
                            setUsdMaxDiscount(0);
                            return;
                          }

                          setUsdMaxDiscount(intValue);
                          setIsUsdMaxDiscountError(false);
                        }}
                      />
                      <label className="text-soft mt-2">Tomans Value</label>
                      <input
                        type="number"
                        className="form-control"
                        style={{
                          padding: "0.7rem 1rem",
                          borderColor: isTomansMaxDiscountError ? "red" : "inherit",
                          color: isTomansMaxDiscountError ? "red" : "inherit",
                        }}
                        placeholder="Enter Max Discount in Tomans"
                        value={tomansMaxDiscount}
                        min={0}
                        onChange={(e) => {
                          const intValue = parseInt(e.target.value);
                          if (intValue < 0) {
                            setTomansMaxDiscount(0);
                            return;
                          }

                          setTomansMaxDiscount(intValue);
                          setIsTomansMaxDiscountError(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </Card>
            )}
            {/* Max Usage Limit */}
            <Card className="card-bordered card-stretch p-3 mt-3">
              <div>
                <h6 className="text-soft">Max Usage Limit</h6>
                <label className="d-flex align-items-center" htmlFor="noMaxUsage">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="maxUsage"
                    id="noMaxUsage"
                    checked={hasMaxUsage === false}
                    onChange={(e) => {
                      setHasMaxUsage(false);
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    No Max Usage limit
                  </span>
                </label>

                <label className="d-flex align-items-center" htmlFor="hasMaxUsage">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="maxUsage"
                    id="hasMaxUsage"
                    checked={hasMaxUsage === true}
                    onChange={(e) => {
                      setHasMaxUsage(true);
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Set Max Usage limit
                  </span>
                </label>
                {hasMaxUsage && (
                  <>
                    <label className="text-soft mt-2">Max Usage Limit</label>
                    <input
                      type="number"
                      className="form-control"
                      style={{
                        padding: "0.7rem 1rem",
                        borderColor: isMaxUsageError ? "red" : "inherit",
                        color: isMaxUsageError ? "red" : "inherit",
                      }}
                      placeholder="Enter Max Discount in USD"
                      value={maxUsage}
                      min={0}
                      onChange={(e) => {
                        const intValue = parseInt(e.target.value);
                        if (intValue < 0) {
                          setMaxUsage(0);
                          return;
                        }

                        setMaxUsage(intValue);
                        setIsMaxUsageError(false);
                      }}
                    />
                  </>
                )}
              </div>
            </Card>

            <Card className="card-bordered card-stretch p-3 mt-3">
              <div>
                <h6 className="text-soft">Expiry Date</h6>
                <label className="d-flex align-items-center" htmlFor="noExpiryDate">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="expiryDate"
                    id="noExpiryDate"
                    checked={hasExpiryDate === false}
                    onChange={(e) => {
                      setHasExpiryDate(false);
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    No Expiry Date
                  </span>
                </label>

                <label className="d-flex align-items-center" htmlFor="hasExpiryDate">
                  <input
                    type="radio"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "0.5rem",
                    }}
                    name="expiryDate"
                    id="hasExpiryDate"
                    checked={hasExpiryDate === true}
                    onChange={(e) => {
                      setHasExpiryDate(true);
                      setExpiresOn(new Date().toISOString());
                    }}
                  />
                  <span
                    className="text-soft"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Set Expiry Date
                  </span>
                </label>
                {hasExpiryDate && (
                  <>
                    <label className="text-soft mt-2">Expiry Date</label>
                    <DatePicker
                      selected={expiresOn ? new Date(expiresOn) : new Date()}
                      className={`form-control ${isExpiresOnError ? "border-danger text-danger" : ""}`}
                      onChange={(date) => {
                        if (date) {
                          const jsDate = new Date(date);
                          setExpiresOn(jsDate.toISOString());
                          setIsExpiresOnError(false);
                        }
                      }}
                      minDate={new Date()}
                    />
                  </>
                )}
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-bordered card-stretch p-3">
              <h6 className="border-bottom pb-2 mb-3 text-soft">Discount Summary</h6>
              <p className="text-soft fw-bold fs-6 m-0 p-0">{name ? name : "Discount Name"}</p>
              <ul
                className=""
                style={{
                  listStyle: "disc",
                  listStylePosition: "inside",
                }}
              >
                {type === "percentage" ? (
                  <li>{usdValue && !isNaN(usdValue) ? usdValue : 0}% Off on Total value</li>
                ) : (
                  <>
                    <li>{usdValue && !isNaN(usdValue) ? usdValue : 0} USD Off on Total value</li>
                    <li>{tomansValue && !isNaN(tomansValue) ? tomansValue : 0} Tomans Off on Total value</li>
                  </>
                )}

                {hasMinOrderValue ? (
                  <>
                    <li>{usdMinOrderValue && !isNaN(usdMinOrderValue) ? usdMinOrderValue : 0} USD Min Order Value</li>
                    <li>
                      {tomansMinOrderValue && !isNaN(tomansMinOrderValue) ? tomansMinOrderValue : 0} Tomans Min Order
                      Value
                    </li>
                  </>
                ) : (
                  <li>No Min Order Value</li>
                )}

                {hasMaxDiscount ? (
                  <>
                    <li>{usdMaxDiscount && !isNaN(usdMaxDiscount) ? usdMaxDiscount : 0} USD Max Discount limit</li>
                    <li>
                      {tomansMaxDiscount && !isNaN(tomansMaxDiscount) ? tomansMaxDiscount : 0} Tomans Max Discount limit
                    </li>
                  </>
                ) : (
                  <li>No Max Discount limit</li>
                )}

                {hasMaxUsage ? (
                  <li>Can be used {maxUsage && !isNaN(maxUsage) ? maxUsage : 0} times</li>
                ) : (
                  <li>No Max Usage limit</li>
                )}

                {hasExpiryDate ? (
                  <li>{expiresOn ? `Expires on ${formatDate(expiresOn, "DD-MM-YYYY")}` : "Expiry Date not set"}</li>
                ) : (
                  <li>No Expiry Date</li>
                )}
              </ul>
              <Button
                className="btn-primary mt-3 text-center w-100 justify-content-center"
                disabled={isSaving}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isSaving ? <Spinner size="sm" color="light" /> : isEditForm ? "Update Discount" : "Add Discount"}
              </Button>
            </Card>
          </Col>
        </Row>
      </Block>
    </React.Fragment>
  );
}

export default DiscountsForm;

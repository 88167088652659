import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
} from "../../../components/Component";
import { filterStatus } from "./UserData";
import { findUpper } from "../../../utils/Utils";
import EditModal from "./EditModal";
import { toast } from "react-toastify";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { GetAllAdminUsers, UpdateAdminUser } from "../../../network/apis/adminUsers";
import AddAdminModal from "./AddAdminModal";
const csvConfig = mkConfig({ useKeysAsHeaders: true, boolDisplay: ["Yes", "No"], filename: "users" });

const AdminUserList = () => {
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();

  const [editFormData, setEditFormData] = useState({
    name: "",
    displayName: "",
    email: "",
    phone: "",
    status: "Active",
    tradingPlatformId: "",
    address: "",
    address2: "",
    state: "",
    country: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const [fetchingData, setFetchingData] = useState(true);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = users.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.phone.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...users]);
    }
  }, [onSearchText, users]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // Sorting data
  const sortFunc = (params) => {
    setSelectedSort(params);
  };

  // function to reset the form
  const resetForm = () => {
    setEditFormData({
      name: "",
      displayName: "",
      email: "",
      phone: "",
      status: "Active",
      tradingPlatformId: "",
      address: "",
      address2: "",
      state: "",
      country: "",
    });
  };

  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };

  const [updatingUserData, setUpdatingUserData] = useState(false);

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, displayName, email, phone, address, address2, state, country, tradingPlatformId } = submitData;

    setUpdatingUserData(true);

    const userData = data.find((item) => item.uid === editId);
    const updatedData = {
      ...userData,
      name: name,
      fullName: name,
      displayName: displayName,
      email: email,
      phone: phone,
      tradingPlatformId: tradingPlatformId,
      status: editFormData.status.toLowerCase(),
      address: address,
      address2: address2,
      state: state,
      country: country,
    };

    editUser(editId, updatedData)
      .then(() => {
        setUpdatingUserData(false);
        setModal({ edit: false });
        fetchUsers();
      })
      .catch(() => {
        setUpdatingUserData(false);
      });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.uid === id) {
        setEditFormData({
          name: item.name,
          displayName: item.displayName,
          email: item.email,
          status: item.status,
          phone: item.phone,
          tradingPlatformId: item.tradingPlatformId,
          address: item.address,
          address2: item.address2,
          state: item.state,
          country: item.country,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };

  // function to change to suspend property for an item
  const suspendUser = async (id) => {
    const userData = data.find((item) => item.uid === id);
    const updatedData = { ...userData, status: "suspend" };

    toast
      .promise(editUser(id, updatedData), {
        pending: "Suspending user...",
        success: "User suspended successfully",
        error: "Could not suspend user",
      })
      .then(() => {
        fetchUsers();
      });
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchUsers = async () => {
    const res = await GetAllAdminUsers();
    if (res.result === "success") {
      setUsers(res.data);
      setFetchingData(false);
    } else {
      setFetchingData(false);
      setUsers([]);
      toast.error(res.message);
    }
  };

  const editUser = async (userId, data) => {
    const res = await UpdateAdminUser(userId, data);
    if (res.result === "success") {
      fetchUsers();
      toast.success(res.message);
      Promise.resolve();
    } else {
      toast.error(res.message);
      Promise.reject();
    }
  };

  const removeAdminUser = async (userId, data) => {
    const res = await UpdateAdminUser(userId, {
      ...data,
      isAdmin: false,
      role: "user",
    });
    if (res.result === "success") {
      fetchUsers();
      toast.success("Admin access removed from user");
      Promise.resolve();
    } else {
      toast.error(res.message);
      Promise.reject();
    }
  };

  const [filterEmailVerified, setFilterEmailVerified] = useState(false);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    let newData = users;

    // Sorting
    if (selectedSort) {
      if (selectedSort === "asc") {
        newData = newData.sort((a, b) => a.name.localeCompare(b.name));
      } else if (selectedSort === "dsc") {
        newData = newData.sort((a, b) => b.name.localeCompare(a.name));
      }
    }

    if (filterEmailVerified) {
      newData = newData.filter((item) => item.emailVerified);
    }
    if (selectedFilterStatus) {
      newData = newData.filter(
        (item) => item.status?.toLowerCase() === selectedFilterStatus?.value?.toLowerCase() ?? ""
      );
    }

    // Pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = newData.slice(indexOfFirstItem, indexOfLastItem);
    setData(currentItems);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, filterEmailVerified, itemPerPage, selectedFilterStatus, users, selectedSort]);

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      {fetchingData ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Admin Users Lists
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {users?.length ?? 0} admin users.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li>
                        <Button
                          color="light"
                          outline
                          className="btn-white"
                          onClick={() => {
                            const csv = generateCsv(csvConfig)(users);
                            download(csvConfig)(csv);
                          }}
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools"></div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <div className="toggle-wrap">
                          <Button
                            className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                            onClick={() => updateTableSm(true)}
                          >
                            <Icon name="menu-right"></Icon>
                          </Button>
                          <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </li>
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                    <Icon name="filter-alt"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    end
                                    className="filter-wg dropdown-menu-lg"
                                    style={{ overflow: "visible" }}
                                  >
                                    <div className="dropdown-head">
                                      <span className="sub-title dropdown-title">Filter Users</span>
                                    </div>
                                    <div className="dropdown-body dropdown-body-rg">
                                      <Row className="gx-6 gy-3">
                                        <Col size="12">
                                          <div className="form-group">
                                            <label className="overline-title overline-title-alt">Status</label>
                                            <RSelect
                                              options={filterStatus}
                                              placeholder="Any Status"
                                              onChange={(e) => {
                                                setSelectedFilterStatus(e);
                                              }}
                                              value={selectedFilterStatus}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="dropdown-foot between">
                                      <a
                                        href="#reset"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setData([...users]);
                                          setFilterEmailVerified(false);
                                          setSelectedFilterStatus(null);
                                        }}
                                        className="clickable"
                                      >
                                        Reset Filter
                                      </a>
                                    </div>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    color="tranparent"
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                  >
                                    <Icon name="setting"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end className="dropdown-menu-xs">
                                    <ul className="link-check">
                                      <li>
                                        <span>Show</span>
                                      </li>

                                      <li className={itemPerPage === 10 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(10);
                                          }}
                                        >
                                          10
                                        </DropdownItem>
                                      </li>
                                      <li className={itemPerPage === 15 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(15);
                                          }}
                                        >
                                          15
                                        </DropdownItem>
                                      </li>
                                      <li className={itemPerPage === 30 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(30);
                                          }}
                                        >
                                          30
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                    <ul className="link-check">
                                      <li>
                                        <span>Order</span>
                                      </li>
                                      <li className={sort === "dsc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("dsc");
                                            sortFunc("dsc");
                                          }}
                                        >
                                          DESC
                                        </DropdownItem>
                                      </li>
                                      <li className={sort === "asc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("asc");
                                            sortFunc("asc");
                                          }}
                                        >
                                          ASC
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">User</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Phone</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Status</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span
                      className="sub-text"
                      style={{
                        textAlign: "right",
                      }}
                    >
                      Action
                    </span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {data.length > 0
                  ? data.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow>
                            <div className="user-card">
                              <UserAvatar
                                theme={item.avatarBg}
                                text={findUpper(item.name)}
                                image={item.photoURL}
                              ></UserAvatar>
                              <div className="user-info">
                                <span className="tb-lead">
                                  {item.name}{" "}
                                  <span
                                    className={`dot dot-${
                                      item.status === "Active"
                                        ? "success"
                                        : item.status === "Pending"
                                        ? "warning"
                                        : "danger"
                                    } d-md-none ms-1`}
                                  ></span>
                                </span>
                                <span>{item.email}</span>
                              </div>
                            </div>
                          </DataTableRow>

                          <DataTableRow size="md">
                            <span>{item.phone}</span>
                          </DataTableRow>

                          <DataTableRow size="md">
                            <span
                              className={`tb-status text-${
                                item?.status?.toLowerCase() === "active"
                                  ? "success"
                                  : item?.status?.toLowerCase() === "pending"
                                  ? "warning"
                                  : "danger"
                              }`}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {item.status}
                            </span>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li onClick={() => onEditClick(item.uid)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>

                                      <li onClick={() => removeAdminUser(item.uid, item)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="user-remove"></Icon>
                                          <span>Remove Admin Access</span>
                                        </DropdownItem>
                                      </li>
                                      {item.status !== "Suspend" && (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => suspendUser(item.uid)}>
                                            <DropdownItem
                                              tag="a"
                                              href="#suspend"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="na"></Icon>
                                              <span>Suspend User</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      )}
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {data.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={users.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>

          <AddAdminModal modal={modal.add} closeModal={closeModal} refreshData={fetchUsers} />

          <EditModal
            modal={modal.edit}
            formData={editFormData}
            setFormData={setEditFormData}
            closeModal={closeEditModal}
            onSubmit={onEditSubmit}
            filterStatus={filterStatus}
            updating={updatingUserData}
            refreshData={() => {
              fetchUsers();
            }}
            userUid={editId}
          />
        </Content>
      )}
    </React.Fragment>
  );
};
export default AdminUserList;

import React from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { Card } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  BlockBetween,
} from "../components/Component";
import { Link } from "react-router-dom";
import { svgData } from "./components/crafted-icons/NioIconData";

const Homepage = () => {
  const introCard = [
    {
      icon: "profile",
      title: "Users",
      description: "Manage all users in system. You can add, update, delete users and also manage their roles.",
      linkTo: "/users",
      linkText: "Manage Users",
    },
    {
      icon: "reports",
      title: "All Subscriptions",
      description: "Check out all subscriptions of all users. You can also manage their subscriptions.",
      linkTo: "/subscriptions",
      linkText: "Manage Subscriptions",
    },
    {
      icon: "invoice",
      title: "Payments History",
      description: "Check out all payment history of all users. You can also manage their payments.",
      linkTo: "/history-payment",
      linkText: "Manage Payments",
    },
    {
      icon: "plan-1",
      title: "Pricing Tables",
      description: "Check out all pricing tables. You can also manage pricing tables.",
      linkTo: "/pricing-table",
      linkText: "Manage Pricing Tables",
    },
    {
      icon: "card-credit",
      title: "Discounts",
      description: "Check out all discounts. You can also manage discounts.",
      linkTo: "/discounts",
      linkText: "Manage Discounts",
    },
    {
      icon: "settings",
      title: "Admin Users",
      description: "Check out all admin users. You can also manage admin users.",
      linkTo: "/admin-users",
      linkText: "Manage Admin Users",
    },
  ];

  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Dashboard
              </BlockTitle>
              <BlockDes>
                <p>Welcome to our dashboard. Manage your account and your subscriptions.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {introCard.map((item, index) => {
              const icon = svgData.filter((icon) => icon.slug === item.icon)[0];
              return (
                <Col md="6" key={index} className="mx-auto">
                  <Card className="card-bordered card-full">
                    <div className="nk-wg1">
                      <div className="nk-wg1-block">
                        <div className="nk-wg1-img">{icon.svg}</div>
                        <div className="nk-wg1-text">
                          <h5 className="title">{item.title}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="nk-wg1-action">
                        <Link to={`${process.env.PUBLIC_URL}${item.linkTo}`} className="link">
                          <span>{item.linkText}</span> <Icon name="chevron-right"></Icon>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;

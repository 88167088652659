import { get, put, del, post } from "../../network/Config/apiService";

const getAllDiscounts = async () => {
  try {
    const res = await get("/admin/discounts");
    const data = res.data.discounts;

    return {
      status: "success",
      message: "Discounts fetched successfully",
      data: data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not fetch discounts",
      data: null,
    };
  }
};

const AddDiscount = async (data) => {
  try {
    await post("/admin/discount/add", data);

    return {
      status: "success",
      message: "Discount added successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not add discount",
    };
  }
};

const UpdateDiscount = async (uuid, data) => {
  try {
    await put(`/admin/discount/${uuid}`, data);

    return {
      status: "success",
      message: "Discount updated successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not update discount",
    };
  }
};

const DeleteDiscount = async (uuid) => {
  try {
    await del(`/admin/discount/${uuid}`);

    return {
      status: "success",
      message: "Discount deleted successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not delete discount",
    };
  }
};

export { getAllDiscounts, AddDiscount, UpdateDiscount, DeleteDiscount };
